import React from 'react'
import {MainLayout} from '../../layout'
import {ConfirmEmailForm} from '../../components/auth'
import {confirmEmail, isEmailVerified, sendEmailConfirmationCode} from '../../backend'
import {BooleanParam, StringParam, useQueryParam} from 'use-query-params'
import {navigate} from '../../utils'
import {useAuth} from '../../hooks'

export default function ConfirmEmail() {
  const {setEmailVerified} = useAuth()
  const [phone] = useQueryParam('phone', StringParam)
  const [hideSteps] = useQueryParam('hide_steps', BooleanParam)
  const [returnTo] = useQueryParam('return_to', StringParam)

  const handleEmailConfirmation = async ({code}) => {
    await confirmEmail({code})
    const emailVerified = await isEmailVerified()
    setEmailVerified(emailVerified)
    navigate(returnTo || '/manage/spaces', false)
  }

  const handleResendCode = async () => {
    return sendEmailConfirmationCode({phone})
  }

  return (
    <MainLayout>
      <ConfirmEmailForm onResendCode={handleResendCode} onConfirm={handleEmailConfirmation} hideSteps={hideSteps}/>
    </MainLayout>
  )
}
